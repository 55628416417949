// App.tsx
import type { FC } from 'react';
import React from 'react';

// Assuming SliderThumb is a styled component or similar
import { SliderThumb } from './styles';

// Define a type for the props (adjust as needed based on SliderThumb's actual props)
interface ThumbProps extends React.HTMLAttributes<HTMLDivElement> {}

const Thumb: FC<ThumbProps> = (props) => {
  return <SliderThumb {...props}>{'| |'}</SliderThumb>;
};

export default Thumb;
