// @ts-nocheck
/* eslint-enable */
// App
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import PriceInput from 'app/shared/modules/MobileSRP/Header/FilterModules/PriceFilter/PriceInput';
import SliderThumb from 'app/shared/modules/CollectionResultPage/PriceFilter/SliderThumb';
import SliderTrack from 'app/shared/modules/CollectionResultPage/PriceFilter/SliderTrack';

// Hooks
import { useFilterChange, useFilterTracking } from 'app/shared/modules/MobileSRP/Header/FilterBar/hooks';

// Misc / utils
import VALUE_CONSTANTS from 'app/shared/constants/ValueConstants';
import { PRICE_SLIDER_MAX } from 'app/shared/constants/FilterConstants';
import {
  GeneralFilterHeader,
  GeneralFilterLabel,
  GeneralFilterResetButton,
  GeneralFilterSection,
} from 'app/shared/modules/MobileSRP/Header/FilterBar/styles';
import { DEFAULT } from 'app/shared/models/Filter';
import * as S from './styles';
import { TrackHighPriceFilter, TrackLowPriceFilter } from 'app/shared/models/Clickstream/FilterClickstreamEvents';

const HUNDRED_TO_ONE_SCALE = VALUE_CONSTANTS.PRICE_FILTER_INCREMENT;

/**
 * Price slider - A component that contains display and business logic for the price filter
 * @param {Object} props - props object
 * @param {boolean} props.showResetButton [showResetButton=false] - Show or hide reset button
 * @param {boolean} props.preventDefault [preventDefault=false] - if true, only carry out Redux filter change (do not fecth listings)
 * @param {string} props.customTrackingEvent [customTrackingEvent=null] - if present, use event instead of default filter event
 * @returns component
 */
const PriceSlider = ({
  showResetButton = false,
  preventDefault = false,
  customTrackingEvent = null,
  triggerLocation = null,
  triggerObject = null,
}) => {
  // Redux
  const price = useSelector((state) => state.filter.price);

  // React state
  const [priceState, setPriceState] = useState(price);
  const [sliderValue, setSliderValue] = useState([
    priceState.min ? priceState.min / VALUE_CONSTANTS.PRICE_FILTER_INCREMENT : 0,
    priceState.max ? priceState.max / VALUE_CONSTANTS.PRICE_FILTER_INCREMENT : PRICE_SLIDER_MAX,
  ]);

  // Hooks
  const { debouncedFilterChange, reduxOnlyChange, resetFilter, isFilterResetting } = useFilterChange();
  const [trackFilterEvent] = useFilterTracking();

  const handlePriceChange = useCallback(
    (rawVals, isPriceSetViaInput = false) => {
      const [min, max] = rawVals;
      const MULTIPLIER = VALUE_CONSTANTS.PRICE_FILTER_INCREMENT;
      const formattedVals = {
        min: min === 0 ? null : min * MULTIPLIER,
        max: max === PRICE_SLIDER_MAX ? null : max * MULTIPLIER,
      };
      const isInlineFilterEvent = showResetButton;
      setPriceState({ min: formattedVals.min, max: formattedVals.max });

      if (formattedVals.min !== priceState.min) {
        trackFilterEvent({
          ...(customTrackingEvent && {
            customTrackingEvent: {
              ...customTrackingEvent,
              action: 'lowPrice',
            },
          }),
          isInlineFilterEvent,
          filterName: 'lowPrice',
          trackingLabel: isPriceSetViaInput ? 'SetPriceViaInput' : 'SetPriceViaSlider',
          newLaneEvent: TrackLowPriceFilter({ triggerLocation, triggerObject }),
        });
      } else if (formattedVals.max !== priceState.max) {
        trackFilterEvent({
          ...(customTrackingEvent && {
            customTrackingEvent: {
              ...customTrackingEvent,
              action: 'highPrice',
            },
          }),
          isInlineFilterEvent,
          filterName: 'highPrice',
          trackingLabel: isPriceSetViaInput ? 'SetPriceViaInput' : 'SetPriceViaSlider',
          newLaneEvent: TrackHighPriceFilter({ triggerLocation, triggerObject }),
        });
      }
    },
    [priceState, setPriceState],
  );

  const handleSliderOnChange = useCallback((valArr) => {
    setSliderValue(valArr);
  }, []);

  const handleSliderOnAfterChange = useCallback(
    (valArr) => {
      handlePriceChange(valArr);
    },
    [handlePriceChange],
  );

  const handleSliderMinValueChange = useCallback(
    (val) => {
      const valArr = [val, sliderValue[1]];
      setSliderValue(valArr);
      handlePriceChange(valArr, true);
    },
    [sliderValue, handlePriceChange],
  );
  const handleSliderMaxValueChange = useCallback(
    (val) => {
      const valArr = [sliderValue[0], val];
      setSliderValue(valArr);
      handlePriceChange(valArr, true);
    },
    [sliderValue, handlePriceChange],
  );

  const ariaValueText = useCallback((state) => {
    return `Current ${state.index === 0 ? 'min' : 'max'} value: ${
      state.valueNow === 0 ? 'No minimum' : `${state.valueNow * VALUE_CONSTANTS.PRICE_FILTER_INCREMENT} dollars`
    }`;
  }, []);

  const handleResetClick = useCallback(() => {
    resetFilter({ price: DEFAULT.price, preventDefault });
    setPriceState(DEFAULT.price);
    setSliderValue([0, PRICE_SLIDER_MAX]);
  }, [resetFilter]);

  // Listens for filter changes => updates Redux reducer
  useEffect(() => {
    if (!isFilterResetting) {
      if (preventDefault) {
        reduxOnlyChange({ price: priceState });
      } else {
        debouncedFilterChange({ price: priceState });
      }
    }
  }, [priceState]);

  return (
    <GeneralFilterSection>
      <GeneralFilterHeader>
        <GeneralFilterLabel id="price-label">Price range</GeneralFilterLabel>
        {showResetButton && (
          <GeneralFilterResetButton onClick={handleResetClick} aria-label={'Reset all active price filters'}>
            Reset
          </GeneralFilterResetButton>
        )}
      </GeneralFilterHeader>

      <S.InputContainer>
        <PriceInput
          ariaLabel={'Set minimum price'}
          handleSliderValueChange={handleSliderMinValueChange}
          id={'min-price-input'}
          isMinInput
          name={'min-price-input'}
          placeholder={'No min'}
          value={sliderValue[0] * HUNDRED_TO_ONE_SCALE}
        />
        <S.RangeText aria-hidden="true">to</S.RangeText>
        <PriceInput
          ariaLabel={'Set maximum price'}
          handleSliderValueChange={handleSliderMaxValueChange}
          id={'max-price-input'}
          isMaxInput
          name={'max-price-input'}
          placeholder={'No max'}
          value={sliderValue[1] * HUNDRED_TO_ONE_SCALE}
        />
      </S.InputContainer>
      <S.PriceSlider
        aria-labelledby="price-info-card-label"
        ariaLabel={['Minimum price thumb', 'Maximum price thumb']}
        ariaValuetext={ariaValueText}
        min={0} // 1:100 scale
        max={PRICE_SLIDER_MAX} // 1:100 scale
        value={sliderValue} // 1:100 scale
        onChange={handleSliderOnChange}
        onAfterChange={handleSliderOnAfterChange}
        renderTrack={SliderTrack}
        renderThumb={SliderThumb}
      />
    </GeneralFilterSection>
  );
};

export default PriceSlider;
