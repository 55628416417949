// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { arrowPointDown, arrowPointUp, noUserSelect, smoothTransition, textOverflow } from 'app/shared/styles/_mixins';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';
import { zindex } from 'app/shared/styles/_zindex';
import TextBase from 'app/shared/core/Text';

export const ButtonDropdown = styled.div`
  ${smoothTransition};
  ${font.sm};
  position: relative;
  border: 1px solid ${colors['$hpx-grey-500']};
  background-color: ${colors['$hpx-white']};
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: ${colors['$hpx-blue-600']};
  height: 100%;
  &:focus,
  &:hover,
  &:active {
    border-color: ${colors['$hpx-teal-500']};
  }

  ${(props) =>
    props.theme === 'gray' &&
    `
        color: ${colors['$hpx-grey-600']};
    `}

  ${(props) =>
    props.theme === 'teal' &&
    `
        border: 1px solid ${colors['$hpx-teal-500']};
    `}
`;

export const Text = styled(TextBase)`
  padding-right: 4px;

  ${(props) =>
    props.theme === 'teal' &&
    `
        font-weight: bold;
        color: ${colors['$hpx-teal-600']};
    `}
`;

export const ButtonDropdownArrow = styled.img`
  ${arrowPointDown};
  ${textOverflow};
  pointer-events: none;
  position: absolute;

  ${(props) =>
    props.size === 'sm' &&
    `
        right: 12px;
        bottom: 9px;
        height: 12px;
        width: 12px;
    `};
  ${(props) =>
    props.size === 'md' &&
    `
        right: 12px;
        bottom: 14px;
        height: 12px;
        width: 12px;
    `};
  ${(props) =>
    props.size === 'lg' &&
    `
        right: 12px;
        bottom: 14px;
        height: 16px;
        width: 16px;
    `};
  ${(props) => props.isOpen && arrowPointUp};
`;

export const ButtonDropdownDropdownContent = styled.div`
  position: absolute;
  margin-top: 16px;
  left: -2px;
  background: ${colors['$hpx-white']};
  border: 1px solid ${colors['$hpx-grey-500']};
  border-radius: 4px;
  z-index: ${zindex['$z-index-loader']};

  ${(props) =>
    props.dropdownRight &&
    `
        right: -2px;
        left: auto;
    `}

  ${(props) =>
    props.noBorder &&
    `
        border: 0;
        left: -1px;
        ${
          props.dropdownRight
            ? `
            right: -1px;
            left: auto;
        `
            : ''
        }
    `}
`;

export const ButtonDropdownClickable = styled.button`
  width: 100%;
  height: 100%;
  padding: 6px;
  padding-right: 35px;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.size === 'sm' &&
    `
        padding-left: 12px;
        padding-right: 28px;
    `}
  ${(props) =>
    props.size === 'md' &&
    `
        padding-left: 12px;
        padding-right: 32px;
    `}
    ${(props) =>
    props.size === 'lg' &&
    `
        padding-left: 16px;
        padding-right: 40px;
    `}
`;

export const ButtonDropdownDoneBtn = styled.div`
  text-align: center;
  padding: 12px;
  border-top: 1px solid ${colors['$hpx-grey-100']};
  cursor: pointer;
  color: ${colors['$hpx-teal-500']};
  ${smoothTransition};
  ${noUserSelect}
  &:hover {
    color: ${colors['$hpx-teal-600']};
  }
  &:active {
    color: ${colors['$hpx-teal-700']};
  }
`;

export const ButtonDropdownDoneBtnSm = styled.button`
  ${font.sm};
`;

export const ButtonDropdownReset = styled.div`
  ${font.sm};
  position: absolute;
  top: 16px;
  right: 16px;
  color: ${colors['$hpx-grey-600']};
  text-decoration: underline;
  cursor: pointer;
  padding: 8px;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
`;
