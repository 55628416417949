// @ts-nocheck
/* eslint-enable */
import React from 'react';
import startsWith from 'lodash/startsWith';
import formatter from 'app/shared/utils/formatter';
import { itemMatchCX } from './styles';

const controller = {
  getSortedItems(items = []) {
    const sortType = items[0].areaType ? 'areaType' : 'type';
    const sortedItems = {};
    const sortedAreaTypes = [];

    items.forEach((item) => {
      if (!sortedItems[item[sortType]]) {
        sortedItems[item[sortType]] = [item];
        sortedAreaTypes.push(item[sortType]);
      } else {
        sortedItems[item[sortType]].push(item);
      }
    });

    return sortedAreaTypes.map((areaType) => {
      return {
        areaType,
        itemList: sortedItems[areaType],
      };
    });
  },
  getAreaTypeDisplayName(type) {
    const DISPLAY_NAMES = {
      Borough: 'Boroughs',
      City: 'Cities',
      Neighborhood: 'Neighborhoods',
      School: 'Schools',
      Property: 'Properties',
      Zip: 'Zipcodes',
      savedSearch: 'Saved search alerts',
    };

    return DISPLAY_NAMES[type];
  },
  getStyledNameFromMatch(name = '', match = '') {
    if (!match) {
      return <span>{name}</span>;
    }
    const nameArr = name.split(' ');
    const matchCap = formatter.string.firstCaps(formatter.string.removePunctuation(match));
    const matchArr = matchCap.split(' ').sort((a, b) => b.length - a.length);
    const result = [];
    let remainder;
    let m = 0;
    let matchSubstr;
    let didInsert = false;

    nameArr.forEach((nameSubstr) => {
      while (m < matchArr.length) {
        // check if an area name is not capitalized
        matchSubstr = nameSubstr === nameSubstr.toLowerCase() ? matchArr[m].toLowerCase() : matchArr[m];
        if (startsWith(nameSubstr, matchSubstr)) {
          result.push(
            <span className={itemMatchCX} data-name="itemMatchCX" key={matchSubstr + m}>
              {matchSubstr}
            </span>,
          );
          remainder = nameSubstr.replace(matchSubstr, '');
          if (remainder) {
            result.push(<span key={remainder + m}>{remainder}</span>);
          }

          didInsert = true;
          matchArr.splice(m, 1);
          break;
        } else {
          m++;
        }
      }
      if (didInsert) {
        /* eslint-disable react/self-closing-comp */
        result.push(<span key={`space-${nameSubstr}`}> </span>);
        didInsert = false;
      } else {
        result.push(<span key={`no-match-${nameSubstr}`}>{`${nameSubstr} `}</span>);
      }
      m = 0;
    });

    return result;
  },
};

export default controller;
