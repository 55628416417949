// @ts-nocheck
/* eslint-enable */
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';

// Components
import Button from 'app/shared/core/Button';
import Modal from 'app/shared/modules/Modal';
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';
import InputV2 from 'app/shared/core/InputV2';

// Misc / Utils
import {
  autoCompleteContainerCx,
  autoCompleteInputContainerCx,
  dropdownCx,
  helpContainerCX,
  helpDescriptionCX,
  helpTermCX,
  inputCx,
  itemContainerCx,
  itemDescriptionCX,
  itemHeaderCx,
  itemIconContainerCX,
  itemIconCX,
  itemNameCX,
  itemNoIconCX,
  itemRecentNumCX,
  itemTextCX,
  loginContainerCX,
  loginTextCX,
  searchIconCx,
  sectionCx,
} from './styles';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import appUtils from 'app/shared/utils/appUtils';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from './controller';
import IconAlert from 'images/icons/alert-dark-grey.svg';
import IconHistory from 'images/icons/history.svg';
import IconLocation from 'images/icons/location.svg';
import IconSearch from 'images/icons/search-v2-gray.svg';
import IconSearchActive from 'images/icons/search-v2.svg';
import { yieldCallback } from '@zillow/yield-callback';
import { TrackSuggestedArea } from 'app/shared/models/Clickstream/ListingsClickstreamEvents';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('modules/autocomplete');
const { bool, string, func, array, object } = PropTypes;
class AutocompleteV2 extends React.Component {
  static propTypes = {
    className: string,
    full: bool,
    help: object,
    hideRecentDesc: bool, // used for homepage with recent search
    includeCancel: bool,
    includeClear: bool,
    isHomepage: bool,
    isLoggedIn: bool,
    isMobile: bool,
    items: array,
    onBlur: func,
    onChange: func,
    onClose: func,
    onFocus: func,
    onSelect: func,
    placeholder: string,
    value: string,
  };

  static defaultProps = {
    className: '',
    full: false,
    help: {},
    hideRecentDesc: false,
    includeCancel: false,
    includeClear: false,
    isHomepage: false,
    isLoggedIn: false,
    isMobile: false,
    items: [],
    onBlur: () => {},
    onChange: () => {},
    onClose: () => {},
    onFocus: () => {},
    onSelect: () => {},
    placeholder: '',
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      index: -1,
      isActive: false,
      dimInputTextColor: true,
    };
    this.input = React.createRef();
    this.itemRefs = [];
    props.items.forEach(() => {
      this.itemRefs.push(React.createRef());
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { items } = this.props;
    const { index } = this.state;

    if (items !== prevProps.items) {
      this.itemRefs = [];
      items.forEach(() => {
        this.itemRefs.push(React.createRef());
      });
    }

    if (index !== prevState.index) {
      if (index === -1) {
        this.input.current.focusInput();
      }
    }
  }

  componentWillUnmount() {
    this.removeClickListenerAndScrollLock();
  }

  addClickListenerOrScrollLock = () => {
    const { full, isMobile } = this.props;
    if (full && isMobile) {
      appUtils.lockVerticalScrolling();
    } else {
      window.addEventListener('mousedown', this.handleMouseDown);
      window.addEventListener('touchstart', this.handleMouseDown);
    }
  };

  removeClickListenerAndScrollLock = () => {
    window.removeEventListener('mousedown', this.handleMouseDown);
    window.removeEventListener('touchstart', this.handleMouseDown);
    appUtils.unlockVerticalScrolling();
  };

  handleMouseDown = (e) => {
    const { onClose } = this.props;

    // close the drop down when the user clicks the header (on SRP)
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.handleToggleDropdown();
      onClose();
    }
  };

  handleFocus = yieldCallback((e) => {
    const { onFocus, dispatch } = this.props;
    this.setState({
      dimInputTextColor: false,
    });
    onFocus(e);

    // prevents filter modal appearing over search
    dispatch(AppActions.setAppStoreBool('showFilterModal', false));
  });

  handleChange = (e) => {
    const { onChange } = this.props;
    const { isActive } = this.state;
    onChange(e);
    this.setState({ index: -1 });
    if (!isActive) {
      // automatically open dropdown when user tabs into input and begins typing
      if (e.target.value.length > 0) {
        this.handleToggleDropdown({ shouldOpen: true });
      }
    }
  };

  handleBlur = yieldCallback((e) => {
    const { onBlur } = this.props;
    onBlur(e);
  });

  handleToggleDropdown = yieldCallback(({ shouldOpen = false } = {}) => {
    const { isActive } = this.state;

    if (shouldOpen || !isActive) {
      this.addClickListenerOrScrollLock();
      this.setState({ isActive: true, index: -1 });
    } else {
      this.removeClickListenerAndScrollLock();
      this.setState({ isActive: false, dimInputTextColor: true });
    }

    this.input.current?.focusInput();
  });

  handleKeyDown = (e) => {
    const { items, value } = this.props;
    const { index, isActive } = this.state;
    const keyCode = e.which;
    const isShiftPressed = e.shiftKey;
    const overMaxIndex = items.length;

    if (keyCode === constants.UP_ARROW_KEYCODE) {
      const prevIndex = index === -1 ? -1 : index - 1;
      this.setState({ index: prevIndex });
      e.preventDefault();
    } else if (keyCode === constants.DOWN_ARROW_KEYCODE) {
      if (isActive) {
        const hasOptions = items.length > 0;
        const isThreeCharPromptShowing = value.length < 3 && value.length > 0; // options do not appear until user has typed in 3 chars.
        if (hasOptions && !isThreeCharPromptShowing) {
          const nextIndex = index === overMaxIndex ? overMaxIndex : index + 1;
          this.setState({ index: nextIndex });
        }
      } else {
        this.handleToggleDropdown({ shouldOpen: true });
      }
      e.preventDefault();
    } else if (keyCode === constants.ESC_KEYCODE) {
      this.setState({ isActive: false, index: -1 });
      this.input.current.focusInput();
      e.preventDefault();
    } else if (keyCode === constants.ENTER_KEYCODE) {
      const selectedItem = items.length > 0 && index === -1 ? items[0] : items[index];
      if (selectedItem) {
        e.target.blur();
        this.setState({ isActive: false });
        this.handleSelectItem({ item: selectedItem });
      } else {
        logger?.info('User did not select an option. Returning text as value.');
        this.handleSelectItem({ item: value, isActive: true, type: 'userQuery' });
      }
    } else if (keyCode === constants.TAB_KEYCODE) {
      if (
        isShiftPressed || // back tab
        !this.shouldShowSignInButton() // sign in button blur event already toggles the dropdown
      ) {
        this.setState({ isActive: false, index: -1 });
      }
    }
  };

  handleSelectItem = ({ item, isActive = false, type = 'hpSuggestion' }) => {
    const { dispatch, isHomepage, onSelect } = this.props;
    this.setState({ isActive });

    if (type === 'hpSuggestion') {
      dispatch(
        analyticsEvent(gaEvents.SEARCH_QUERY_INPUT, {
          action: `Suggestion::${item.name}`,
          newLaneEvent: TrackSuggestedArea({
            triggerLocation: isHomepage ? 'hp_homepage' : 'search_results',
            triggerObject: isHomepage ? 'hp_home_page_component|search' : 'filter_panel',
          }),
        }),
      );
    } else if (type === 'userQuery') {
      dispatch(
        analyticsEvent(gaEvents.SEARCH_QUERY_INPUT, {
          action: `Query::${item}`,
        }),
      );
    }

    this.removeClickListenerAndScrollLock();
    onSelect(item);
  };

  handleLogin = yieldCallback(() => {
    const { dispatch } = this.props;
    dispatch(analyticsEvent(gaEvents.USER_ACTION_SIGN_IN));
    dispatch(
      AppActions.showPopupModal('PromptLogin', {
        caption: 'Track your favorite listings and take your search history with you.',
      }),
    );
  });

  shouldShowSignInButton = () => {
    const { isLoggedIn, hasRecentSearch } = this.props;
    return !isLoggedIn && hasRecentSearch;
  };

  renderItemIcon = (type) => {
    switch (type) {
      case 'recentSearch':
        return <img className={itemIconCX} src={IconHistory} width="16px" height="16px" alt="" />;
      case 'savedSearch':
        return <img className={itemIconCX} src={IconAlert} width="16px" height="16px" alt="" />;
      case 'geoip':
        return <img className={itemIconCX} src={IconLocation} width="16px" height="16px" alt="" />;
      default:
        return <img className={itemNoIconCX} />;
    }
  };

  renderItems = (items) => {
    const { full, isHomepage } = this.props;
    const sortedItems = controller.getSortedItems(items);
    let idx = -1;

    const headerStyles = itemHeaderCx();
    const sectionStyles = sectionCx();

    return sortedItems.map(({ areaType, itemList }) => {
      const sectionId = `section-${areaType}-label-id`;
      return (
        <div
          className={cx(
            'AutocompleteV2-section',
            sectionStyles.base,
            full && sectionStyles.full,
            !isHomepage && full && sectionStyles.fullSrp,
          )}
          key={areaType}
          data-name="AutocompleteV2Section"
        >
          {areaType && areaType !== 'geoip' && areaType !== 'recentSearch' && (
            <li
              role="presentation"
              id={sectionId}
              tabIndex="-1"
              className={cx(
                'AutocompleteV2-item-header',
                headerStyles.base,
                full && headerStyles.headerFull,
                full && sectionStyles.headerFull,
              )}
              data-name="ItemHeader"
            >
              {controller.getAreaTypeDisplayName(areaType)}
            </li>
          )}
          {itemList.map((item) => {
            idx++;
            return this.renderItem(item, idx, this.itemRefs[idx], sectionId);
          })}
        </div>
      );
    });
  };

  renderItem = (item, i, ref, sectionId) => {
    const { index } = this.state;
    const { value, hideRecentDesc, full } = this.props;
    const styledName = controller.getStyledNameFromMatch(item.nameV2 || item.name, value);
    const isRecentSearch = item.type === 'recentSearch';
    const isSelected = i === index;

    const itemContainerStyles = itemContainerCx();

    return (
      <li
        className={cx(
          'AutocompleteV2-item',
          itemContainerStyles.base,
          full && itemContainerStyles.itemFull,
          isRecentSearch && itemContainerStyles.recent,
          isSelected && itemContainerStyles.active,
        )}
        data-name="ItemContainer"
        key={item.name + item.description + i}
        onMouseOver={() => {
          this.setState({ index: i });
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.handleSelectItem({ item });
        }}
        aria-describedby={sectionId}
        role="option"
        tabIndex="-1"
        aria-selected={isSelected}
        id={value}
        ref={ref}
      >
        <div className={itemIconContainerCX} data-name="ItemIconContainer">
          {this.renderItemIcon(item.type)}
        </div>
        <div className={itemTextCX} data-name="itemTextCX">
          <div className={itemNameCX} data-name="itemNameCX">
            {isRecentSearch && <span className="itemRecentCX">Last search: </span>}
            {styledName}
          </div>
          {item.description && !(isRecentSearch && hideRecentDesc) && (
            <span className={itemDescriptionCX}>{item.description}</span>
          )}
        </div>
        {isNumber(item.numberOfNewResults) && <div className={itemRecentNumCX}>{item.numberOfNewResults} new</div>}
      </li>
    );
  };

  renderHelp = ({ title, description, term }) => {
    return (
      <div className={helpContainerCX} data-name="helpContainerCX">
        <div>
          {title}
          <span className={helpTermCX} data-name="helpTermCX">
            {term}
          </span>
        </div>
        {description && (
          <div className={helpDescriptionCX} data-name="helpDescriptionCX">
            {description}
          </div>
        )}
      </div>
    );
  };

  renderLogin = () => {
    return (
      <div className={loginContainerCX} data-name="loginContainerCX">
        <div className={loginTextCX} data-name="loginTextCX">
          Tip: Sign in to view your Saved Alerts
        </div>
        <Button btnType="primary-outline" onClick={this.handleLogin} onBlur={this.handleToggleDropdown}>
          Sign In
        </Button>
      </div>
    );
  };

  render() {
    const {
      className,
      help,
      isHomepage,
      includeCancel,
      includeClear,
      isMobile,
      items,
      full,
      onClear,
      placeholder,
      value,
    } = this.props;
    const { dimInputTextColor, isActive, index } = this.state;
    const selectedItem = items.length > 0 && index === -1 ? {} : items[index];

    const inputStyles = inputCx();
    const inputContainerStyles = autoCompleteInputContainerCx();
    const searchIconStyles = searchIconCx();

    // the Input's `readOnly` field is to prevent the browser from incorrectly populating field with saved auth (HPWEB-5367)
    const AutocompleteInputContainer = (
      <div
        className={cx(
          'AutocompleteV2-input-container',
          isHomepage && inputContainerStyles.homepage,
          full && !isActive && inputContainerStyles.full,
          full && isActive && inputContainerStyles.fullActive,
          !isHomepage && full && inputContainerStyles.srpFull,
          !isHomepage && full && isActive && inputContainerStyles.srpFullActive,
        )}
        data-name="AutocompleteInputContainerCX"
      >
        {!isHomepage && (
          <img
            decoding="async"
            src={isActive ? IconSearchActive : IconSearch}
            className={cx(
              'AutocompleteV2-search-icon',
              searchIconStyles.base,
              full && searchIconStyles.full,
              !isHomepage && full && searchIconStyles.srpFull,
              full && isActive && searchIconStyles.fullActive,
              !isHomepage && full && isActive && searchIconStyles.srpFullActive,
            )}
            data-name="AutocompleteV2SearchIcon"
            alt=""
          />
        )}
        <InputV2
          className={cx(
            'AutocompleteV2-input',
            inputStyles.base,
            isHomepage && inputStyles.homepage,
            full && !isActive && inputStyles.full,
            !isHomepage && full && inputStyles.srpFull,
            full && isActive && inputStyles.fullActive,
            !isHomepage && full && isActive && inputStyles.srpFullActive,
          )}
          customTagClassName={cx(dimInputTextColor && inputStyles.dimmedText)}
          data-name="AutocompleteV2Input"
          border={isHomepage || !full}
          borderRadius={isMobile && !isActive}
          includeCancel={includeCancel}
          includeClear={includeClear}
          isActive={isActive}
          onBlur={(e) => this.handleBlur(e)}
          onCancel={() => this.handleToggleDropdown({ shouldOpen: false })}
          onChange={(e) => this.handleChange(e)}
          onClear={onClear}
          onFocus={(e) => this.handleFocus(e)}
          onClick={() => this.handleToggleDropdown({ shouldOpen: true })}
          placeholder={placeholder}
          aria-label={`Search listings${value ? ` in ${value}` : ''}`}
          size="xl"
          value={value}
          width={isActive ? 'full' : ''}
          role="combobox"
          aria-expanded={isActive}
          aria-owns="autocomplete-options"
          aria-activedescendant={selectedItem ? selectedItem.nameV2 || selectedItem.name : null}
          ref={this.input}
        />
        <VisuallyHidden htmlTag="div" role="status" aria-atomic="true" aria-live="polite">
          <span>{items.length} options</span>
        </VisuallyHidden>
      </div>
    );

    const containerStyles = autoCompleteContainerCx();
    const dropdownStyles = dropdownCx();

    return (
      <div
        className={cx(
          'AutoCompleteV2-container',
          className,
          containerStyles.base,
          isHomepage && full && containerStyles.full,
          !isHomepage && full && containerStyles.srpFull,
          full && isActive && containerStyles.fullActive,
          isHomepage && containerStyles.homepage,
        )}
        data-name="AutocompleteV2ContainerCX"
        data-debugName={cx({
          full,
          srpFull: !isHomepage && full,
          fullActive: full && isActive,
          homepage: isHomepage,
        })}
        onKeyDown={(e) => this.handleKeyDown(e)}
      >
        {!(full && isActive) && AutocompleteInputContainer}
        {isActive && (
          <Modal onHidePopup={this.handleToggleDropdown} forceOverlay={full} forceNoOverlay={!full}>
            <ul
              id="autocomplete-options"
              role="listbox"
              className={cx(
                'AutocompleteV2-dropdown',
                dropdownStyles.base,
                full && dropdownStyles.full,
                !isHomepage && full && dropdownStyles.srpFull,
                isHomepage && dropdownStyles.homepage,
              )}
              data-name="AutocompleteV2Dropdown"
            >
              {!isHomepage && AutocompleteInputContainer}
              {!isEmpty(help.title) && this.renderHelp(help)}
              {isEmpty(help.title) &&
                Boolean(items) &&
                Boolean(items.length) &&
                Boolean(items.length > 0) &&
                this.renderItems(items)}
              {this.shouldShowSignInButton() && this.renderLogin()}
            </ul>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hasRecentSearch: !isEmpty(state.user.search.recent),
  isLoggedIn: state.user.loggedIn,
  isMobile: state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs',
});

export default connect(mapStateToProps)(AutocompleteV2);
