// styles.ts
import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { stacked } from 'app/shared/styles/_spacing';
import { font } from 'app/shared/styles/_fontsize';
import { colors } from 'app/shared/styles/_colors';
import { BaseDialog } from '../../Dialog/styles';

// Components
import ReactSlider from 'react-slider';

// Dialog styled component
export const Dialog = styled(BaseDialog)`
  // Mobile
  width: calc(100% - 16px);
  top: 30%;
  left: 8px;

  // Desktop
  @media ${viewports['md-and-up']} {
    width: 350px;
    left: 400px;
    top: 274px;
  }
`;

// FlexSection styled component
export const FlexSection = styled.section`
  flex: 1;
`;

// InputContainer styled component
export const InputContainer = styled.section`
  ${stacked._4x};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

// RangeText styled component
export const RangeText = styled.p`
  ${font.sm};
  color: ${colors['$hpx-grey-600']};
  padding-left: 15px;
  padding-right: 15px;
`;

// PriceSlider styled component
export const PriceSlider = styled(ReactSlider)`
  ${stacked._10x};
  width: 100%;
`;

// SliderThumb styled component
export const SliderThumb = styled.div`
  bottom: -16px;
  cursor: grab;
  line-height: 30px;
  width: 30px;
  border: 1px solid ${colors['$hpx-grey-500']};
  border-radius: 4px;
  box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 15%);
  background: ${colors['$hpx-white']};
  color: ${colors['$hpx-grey-500']};
  font-size: 24px;
  letter-spacing: -1px;
  text-align: center;
  z-index: 3;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

// Define the props interface for SliderTrack
interface SliderTrackProps {
  index: number; // Define the type of index, assuming it's a number
}

// SliderTrack styled component with props type
export const SliderTrack = styled.div<SliderTrackProps>`
  bottom: -6px;
  height: 6px;
  background: ${(props) => (props.index === 1 ? `${colors['$hpx-teal-500']}` : `${colors['$hpx-grey-500']}`)};
  border-radius: 999px;
`;

// DisplayText styled component
export const DisplayText = styled.p`
  ${font.tiny};
  ${stacked._2x};
  text-align: center;
`;
