// @ts-nocheck
/* eslint-enable */
import isString from 'lodash/isString';
import forEach from 'lodash/forEach';

import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import SearchItem from 'app/shared/models/SearchItem';
import queryUtils from 'app/shared/utils/queryUtils';

const controller = {
  findFirstItemMatch(arr = [], arrayItems = [], emptyItem = {}) {
    if (isString(arr)) {
      arr = [arr];
    }
    let itemFound = emptyItem;

    forEach(arr, (itemType) => {
      forEach(arrayItems, (item) => {
        if (item.type === itemType && itemFound === emptyItem) {
          itemFound = item;
        }
      });
    });
    return itemFound;
  },
  validateSelection(selection = {}) {
    const handleFreeformText = isString(selection);
    let searchItem = new SearchItem();
    if (handleFreeformText) {
      searchItem = new SearchItem({
        gaEvent: gaEvents.SEARCH_FREEFORM,
        name: selection,
        type: 'freeform',
      });
    } else if (selection instanceof SearchItem === true) {
      searchItem = selection;
    } else {
      searchItem = new SearchItem();
    }
    return searchItem;
  },
  getMaxNumSuggestions({ full, isMobile, MAX_SUGGESTIONS, MAX_SUGGESTIONS_FULL }) {
    const HEADER_HEIGHT = isMobile ? 44 : 104;
    const SUGGESTION_HEIGHT = isMobile ? 65 : 72; // average - depends on num of subheaders
    let maxSuggestions = MAX_SUGGESTIONS;

    if (full) {
      const numSuggestionsWithinHeight = Math.floor((window.innerHeight - HEADER_HEIGHT) / SUGGESTION_HEIGHT);
      maxSuggestions = Math.min(numSuggestionsWithinHeight, isMobile ? MAX_SUGGESTIONS : MAX_SUGGESTIONS_FULL);
    }
    return maxSuggestions;
  },
  getPlaceholder({ large, areaName } = {}) {
    return !large && areaName ? areaName : 'Find apartments in...';
  },
  getSearchItemSansFilterKeepMap(activeSearchItem) {
    const {
      areaType = '',
      gaEvent = {},
      name = '',
      numberOfNewResults = null,
      resourceId = '',
      type = '',
    } = activeSearchItem;
    let { url = '' } = activeSearchItem;

    if (url) {
      url = queryUtils.removeNonMapQueries(url);
    }

    return new SearchItem({
      areaType,
      description: 'For rent: regular, room for rent and sublet listing types. ',
      gaEvent,
      name,
      numberOfNewResults,
      resourceId,
      type,
      url,
    });
  },
};

export default controller;
